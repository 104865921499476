#home{
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-attachment: fixed;
    -webkit-background-attachment: scroll;
    -moz-background-attachment: fixed;
    -o-background-attachment: fixed;
    background-repeat: no-repeat;
    -webkit-background-repeat: no-repeat;
    -moz-background-repeat: no-repeat;
    -o-background-repeat: no-repeat;
    background-position: center;
    -webkit-background-position: center;
    -moz-background-position: center;
    -o-background-position: center;
}

@media (hover: none) {
    #home {
        background-attachment: initial;
    }
 }